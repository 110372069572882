import PropTypes from 'prop-types';
import '@/styles/globals.scss';
import { Footer, Navbar } from '@/components/global';
import localFont from 'next/font/local';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';

const neue = localFont({
  src: [
    {
      path: '../../public/fonts/Neue/NeueHaasGrotTextRound-55Roman-Web.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Neue/NeueHaasGrotDisp-55Roman-Web.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Neue/NeueHaasGrotDisp-65Medium-Web.woff2',
      weight: '600',
      style: 'normal',
    },
  ],
});

const propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.shape({}),
};

function App({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => {
      const firstNode = document.getElementById('first');
      if (firstNode) firstNode.scrollIntoView();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <>
      <SpeedInsights />
      <Analytics />
      <style jsx global>{`
        html {
          font-family: ${neue.style.fontFamily};
        }
      `}</style>
      <Navbar />
      <div className="page" id="page">
        <Component {...pageProps} />
        <Footer />
      </div>
    </>
  );
}

App.propTypes = propTypes;
export default App;
