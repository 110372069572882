import { Button, Icon } from '@/components/standalone';
import Image from 'next/image';
import { useRef } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Link from 'next/link';
import footer from 'public/images/footer.jpg';
import styles from './Footer.module.scss';

const routeSpecifics = {
  '/': {
    headline: (
      <>
        Let’s make trust
        <br />
        and web3 compatible
      </>
    ),
    cta: (
      <Button type="link" href="mailto:contact@trustblock.run" icon="Mail">
        Get in touch
      </Button>
    ),
    cta2: (
      <Button
        type="link"
        mode="outline"
        href="https://app.trustblock.run"
        icon="ArrowRight"
      >
        Go to app
      </Button>
    ),
  },
  '/auditors': {
    headline: (
      <>
        Build the future
        <br />
        of web3 with us
      </>
    ),
    cta: (
      <Button
        type="link"
        href="https://app.trustblock.run/user/onboard?type=auditor"
        icon="LogIn"
      >
        Get onboard as an auditor
      </Button>
    ),
    cta2: (
      <Button type="link" mode="outline" href="https://app.trustblock.run">
        Go to app
      </Button>
    ),
  },
  '/integrators': {
    headline: (
      <>
        Build the future
        <br />
        of web3 with us
      </>
    ),
    cta: (
      <Button
        type="link"
        href="https://app.trustblock.run/user/onboard?type=integrator"
        icon="LogIn"
      >
        Get onboard as a integrator
      </Button>
    ),
    cta2: (
      <Button type="link" mode="outline" href="https://app.trustblock.run">
        Go to app
      </Button>
    ),
  },
};

function Footer() {
  const footerRef = useRef();
  const router = useRouter();

  return (
    <footer className={styles.footer} ref={footerRef}>
      <div className={classNames('section', styles.footerTop)}>
        <Image
          fill
          src={footer}
          alt="footer"
          quality={100}
          className={styles.footerTopCover}
        />
        <div className={styles.footerTopContent}>
          <h1>
            {(routeSpecifics[router.pathname] || routeSpecifics['/']).headline}
          </h1>
          <div>
            {(routeSpecifics[router.pathname] || routeSpecifics['/']).cta}
            {(routeSpecifics[router.pathname] || routeSpecifics['/']).cta2}
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div>
          <Icon name="TrustblockLogoFull" />
          <p className={styles.footerDescription}>
            We make web3 a secured-by-default,
            <br />
            user-friendly, composable ecosystem.
          </p>
          <p>All rights reserved · Trustblock</p>
        </div>
        <div>
          <div>
            <h5>About</h5>
            <Link href="https://docs.trustblock.run">Introduction</Link>
            <Link href="https://docs.trustblock.run">Documentation</Link>
            <Link href="https://www.notion.so/trustblock/Trustblock-hires-2474e7c8936f40028ac16c4e080cb138">
              Careers
            </Link>
            <Link href="https://trustblock.notion.site/Brand-assets-547b207deecd4239adfd20c10d605c09">
              Brand Assets
            </Link>
          </div>
          <div>
            <h5>Let&apos;s connect</h5>
            <Link href="https://twitter.com/TrustblockHQ">Twitter</Link>
            <Link href="https://www.youtube.com/@TrustblockHQ">Youtube</Link>
            <Link href="https://discord.com/invite/NbdkHYXbTx">Discord</Link>
          </div>
          <div>
            <h5>Backers</h5>
            <Link href="https://fabric.vc" target="_blank" rel="nofollow">
              Fabric
            </Link>
            <Link href="https://frst.vc" target="_blank" rel="nofollow">
              Frst
            </Link>
            <Link
              href="https://www.lecryptofellowship.com/"
              target="_blank"
              rel="nofollow"
            >
              LCF
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
